import { List } from '@material-ui/core'
import ProgressListItem, { ProgressListItemProps } from 'components/ProgressListItem/ProgressListItem'
import * as React from 'react'

export interface ProgressListProps {
    steps: ProgressListItemProps[]
}

const ProgressList = (props: ProgressListProps): JSX.Element => {
    return (
        <List disablePadding={true} className={'ProgressList'}>
            {props.steps.map((item: ProgressListItemProps, index: number) => (
                <ProgressListItem
                    key={item.i18nKey + index}
                    i18nKey={item.i18nKey}
                    status={item.status}
                    subSteps={item.subSteps}
                />
            ))}
        </List>
    )
}

export default ProgressList
