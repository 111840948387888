import { AccountType } from 'graphql/types'

export const getBankAccountApproval = (
    accountType: string,
    differentAccountHolder: boolean,
    b2b?: boolean,
): { consentLabel: string; consentLabel2: string } => {
    let consentLabel =
        'bankDetailsStrings' + (differentAccountHolder ? '.differentAccountHolder' : '.sameAccountHolder')
    let consentLabel2 =
        'bankDetailsStrings' + (differentAccountHolder ? '.differentAccountHolder' : '.sameAccountHolder')

    if (accountType === AccountType.IBAN) {
        consentLabel += b2b ? '.transferCheckboxB2B' : '.transferCheckbox'
        consentLabel2 += '.transferCheckbox2'
    } else {
        consentLabel += '.ibanCheckbox'
        consentLabel2 += '.ibanCheckbox2'
    }

    return { consentLabel, consentLabel2 }
}
