import { useEffect } from 'react'
import { Customize } from './customize'

export default function useVersionTitle(): void {
    // @ts-expect-error: init the customize
    const dataFromJS: Customize = window.customize

    useEffect(
        () => {
            document.title = dataFromJS.globalConfiguration.title
        },
        // eslint-disable-next-line
        [],
    )
}
