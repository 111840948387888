import * as React from 'react'

export interface ArrowIconProps {
    color: string
    width: string
    height: string
}

const ArrowIcon = (props: ArrowIconProps): JSX.Element => {
    return (
        <svg
            width={props.width}
            height={props.height}
            viewBox={'0 0 26 14'}
            fill={'none'}
            xmlns={'http://www.w3.org/2000/svg'}
        >
            <path d={'M13 14L26 0H0L13 14Z'} fill={props.color} />
        </svg>
    )
}

export default ArrowIcon
