import { Box, CircularProgress, Grid } from '@material-ui/core'
import { AuthClientError, AuthClientEvent } from '@react-keycloak/core'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import * as Sentry from '@sentry/react'
import keycloak from 'keycloak'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import SentryInit from 'utils/SentryInit'
import { UserInfoProvider } from 'utils/UserInfoContext'
import App from './App'
import './index.css'

const eventLogger = (event: AuthClientEvent, error?: AuthClientError) => {
    console.log('onKeycloakEvent', event, error)
}

if (process.env.NODE_ENV === 'production') {
    Sentry.init(SentryInit())
}

ReactDOM.render(
    <Suspense
        fallback={
            <Grid container item xs={12}>
                <Box
                    height={1}
                    width={1}
                    display={'flex'}
                    justifyContent={'center'}
                    alignContent={'center'}
                    marginTop={'calc(100vh / 2)'}
                >
                    <CircularProgress size={80} />
                </Box>
            </Grid>
        }
    >
        <React.StrictMode>
            <ReactKeycloakProvider authClient={keycloak()} onEvent={eventLogger}>
                <Sentry.ErrorBoundary fallback={() => <div>An error has occurred</div>} showDialog>
                    <UserInfoProvider>
                        <App />
                    </UserInfoProvider>
                </Sentry.ErrorBoundary>
            </ReactKeycloakProvider>
        </React.StrictMode>
    </Suspense>,
    document.getElementById('root'),
)
