import { Box, Button, CircularProgress, Fade, Grid, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import colors from 'utils/colors'
import TestIDs from 'utils/TestIDs'
import { useOrderDeclinedReducer } from './useOrderDeclinedReducer'

const OrderDeclined = (): JSX.Element => {
    const { t } = useTranslation()
    const { b2b, loadState, salesPartner, newOrder } = useOrderDeclinedReducer()

    return (
        <Box
            minHeight={{ xs: 'calc(100vh - 300px)', sm: 'calc(100vh - 194px)' }}
            paddingLeft={3}
            paddingRight={3}
            paddingTop={2}
            paddingBottom={2}
        >
            {salesPartner && (
                <Grid container direction="column" justifyContent="space-evenly" alignItems="center">
                    <Grid item xs={12}>
                        <Typography style={{ paddingBottom: 40 }} variant="h2">
                            {t('orderDone')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            size="large"
                            fullWidth
                            style={{ backgroundColor: colors.black, color: colors.white }}
                            variant="outlined"
                            onClick={() => newOrder()}
                        >
                            {t('newOrder')}
                        </Button>
                    </Grid>
                </Grid>
            )}
            {!salesPartner && (
                <Grid container spacing={3}>
                    <Grid item xs={12} md={2} />
                    <Grid item xs={12} md={10}>
                        <Typography
                            {...TestIDs.GET_ORDER_CONFIRMATION('HEADER_TYPOGRAPHY')}
                            variant={'h1'}
                            className={clsx({ TextError: !loadState.loading && loadState.errors })}
                        >
                            {!loadState.loading && loadState.errors ? t(loadState.errors) : t('orderDeclinedHeader')}
                        </Typography>
                        <Typography {...TestIDs.GET_ORDER_CONFIRMATION('SUBHEADER_TYPOGRAPHY')} variant={'h4'}>
                            {t('orderDeclinedSubheader')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2} />
                    <Grid item xs={12} md={10}>
                        <Grid container item xs={12} lg={9} xl={7}>
                            <Fade in={loadState.loading} timeout={250} unmountOnExit={true} exit={false}>
                                <Box
                                    height={1}
                                    width={1}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    marginTop={5}
                                >
                                    <CircularProgress size={80} />
                                </Box>
                            </Fade>
                            <Fade in={!loadState.loading} timeout={250} unmountOnExit={true} exit={false}>
                                <Grid container {...TestIDs.GET_ORDER_CONFIRMATION('IMAGE')} spacing={3}>
                                    {/*<Grid item xs={12}>
                                        <Box height={'100%'} width={'100%'} marginBottom={'32px'}>
                                            <img
                                                src={process.env.PUBLIC_URL + '/customize/thanks.png'}
                                                alt={'placeholder'}
                                                style={{ height: '100%', width: '100%' }}
                                            />
                                        </Box>
            </Grid>*/}
                                    <Grid item xs={12}>
                                        <Typography
                                            {...TestIDs.GET_ORDER_DECLINED('TEXT_1')}
                                            className={'UbersichtText'}
                                            dangerouslySetInnerHTML={{
                                                __html: t(b2b ? 'orderDeclinedText1B2B' : 'orderDeclinedText1'),
                                            }}
                                        ></Typography>
                                    </Grid>
                                </Grid>
                            </Fade>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Box>
    )
}

export default OrderDeclined
