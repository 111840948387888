import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import useURLParams from 'utils/URLParamsContex'
import { getRoleAwareTranslationKey, useUserInfo } from 'utils/UserInfoContext'
import ProductCategoryCard from '../../components/ProductCategoryCard'
import ViewWrapper from '../../components/ViewWrapper'
import { ProductCategory } from '../../graphql/types'
import { getSubmitButtonTextForProductBasedViews } from '../../utils/testable/getSubmitButtonTextForProductBasedViews'
import { useProductCategoriesSelectionReducer } from './useProductCategoriesSelectionReducer'

const ProductCategoriesSelection = (): JSX.Element => {
    const { t } = useTranslation()
    const { B2B } = useURLParams()
    const {
        currentView,
        availableProductCategories,
        selectedProductCategories,
        setSelectedProductCategories,
        pagesList,
        currentPage,
    } = useProductCategoriesSelectionReducer()

    const smWidth = (index: number): 1 | 2 | 3 | 4 | 5 | 6 | 12 => {
        // Last one and odd number of elements
        if (availableProductCategories.length % 2 == 1 && index == availableProductCategories.length - 1) return 12
        // Even number of elements or odd, but not last element
        else return 6
    }

    const lgWidth = (index: number): 3 | 4 | 6 | 12 => {
        const count = availableProductCategories.length
        if ([1, 2, 3, 4].includes(count)) return (12 / count) as 3 | 4 | 6 | 12
        else if (count == 5) {
            if (index < 3) return 4
            else return 6
        } else if (count == 6) return 4
        else throw 'unhandled number of productCategoryCards'
    }

    const [userInfo] = useUserInfo()

    const submitButtonText = getSubmitButtonTextForProductBasedViews(pagesList, currentPage, availableProductCategories)

    return (
        <ViewWrapper
            disabledSubmit={selectedProductCategories.length === 0}
            header={getRoleAwareTranslationKey(userInfo, currentView + 'Header')}
            subHeader={getRoleAwareTranslationKey(userInfo, currentView + 'Subheader')}
            viewType={currentView}
            submitButtonText={
                typeof submitButtonText === 'string'
                    ? t(submitButtonText as string)
                    : t(currentView + 'Strings.goTo', {
                          productCategoryType: t(
                              (
                                  submitButtonText as {
                                      productCategoryType: string
                                      selectionStep: string
                                  }
                              ).productCategoryType,
                          ),
                          selectionStep: t(
                              (
                                  submitButtonText as {
                                      productCategoryType: string
                                      selectionStep: string
                                  }
                              ).selectionStep,
                          ),
                      })
            }
        >
            <Grid container spacing={1}>
                {availableProductCategories
                    .sort((a, b) => {
                        return a.sortIndex - b.sortIndex
                    })
                    .map((product: ProductCategory, index: number) => (
                        <Grid item xs={12} sm={smWidth(index)} lg={lgWidth(index)} key={product.title + product.id}>
                            <ProductCategoryCard
                                title={product.title}
                                subTitle={product.subtitle}
                                description={product.description}
                                priceText={product.priceText}
                                selected={selectedProductCategories.findIndex((p) => p.id === product.id) !== -1}
                                id={product.id}
                                onClick={(id: string): void => setSelectedProductCategories({ id, B2B })}
                            />
                        </Grid>
                    ))}
            </Grid>
        </ViewWrapper>
    )
}

export default ProductCategoriesSelection
