import { ProgressListItemProps } from 'components/ProgressListItem/ProgressListItem'
import { ProgressListProps } from '../../components/ProgressList/ProgressList'
import {
    ProgressListItemState,
    ProgressListSubItemtype,
} from '../../components/ProgressListSubItem/ProgressListSubItem'
import { GeneralState, ViewType } from '../../store/GeneralState/GeneralState.reducer'
import { getSelectedProductCategoryFromData } from './basketCalculation'
import { pathToViewType } from './pathToViewType'

export const updateProgressList = (generalState: GeneralState, b2b: boolean): ProgressListProps => {
    const currentPath = generalState.pagesList[generalState.currentPage].path
    const currentViewType = pathToViewType(currentPath)

    const availSteps: ProgressListItemProps = {
        i18nKey: 'availability',
        status: ProgressListItemState.UNFINISHED,
    }
    const confSteps: ProgressListItemProps = {
        i18nKey: 'configureProducts',
        status: ProgressListItemState.UNFINISHED,
    }
    const dataSteps: ProgressListItemProps = {
        i18nKey: 'data',
        status: ProgressListItemState.UNFINISHED,
        subSteps: [
            {
                i18nKey: 'contactData',
                status: ProgressListItemState.UNFINISHED,
            },
            {
                i18nKey: 'bankDetails',
                status: ProgressListItemState.UNFINISHED,
            },
            {
                i18nKey: 'installationDetails',
                status: ProgressListItemState.UNFINISHED,
            },
            {
                i18nKey: 'desiredDate',
                status: ProgressListItemState.UNFINISHED,
            },
        ],
    }

    const vzfSteps: ProgressListItemProps = {
        i18nKey: 'vzfProgress',
        status: ProgressListItemState.UNFINISHED,
    }

    const overviewSteps: ProgressListItemProps = {
        i18nKey: 'overview',
        status: ProgressListItemState.UNFINISHED,
    }
    if (
        generalState.pagesList.findIndex((l) => pathToViewType(l.path) === ViewType.LANDLINE_CONTRACT_OPTIONS) !== -1 &&
        dataSteps.subSteps
    ) {
        dataSteps.subSteps.splice(1, 0, {
            i18nKey: 'landlineContractOptions',
            status: ProgressListItemState.UNFINISHED,
        })
    } else {
        if (dataSteps.subSteps && dataSteps.subSteps[1].i18nKey === 'landlineContractOptions') {
            dataSteps.subSteps.splice(1, 1)
        }
    }

    if (
        generalState.pagesList.findIndex((l) => pathToViewType(l.path) === ViewType.PORTABILITY_DETAILS) !== -1 &&
        dataSteps.subSteps
    ) {
        dataSteps.subSteps.splice(2, 0, {
            i18nKey: 'portabilityDetails',
            status: ProgressListItemState.UNFINISHED,
        })
    } else {
        if (dataSteps.subSteps && dataSteps.subSteps[2].i18nKey === 'portabilityDetails') {
            dataSteps.subSteps.splice(2, 1)
        }
    }

    if (
        generalState.pagesList.findIndex((l) => pathToViewType(l.path) === ViewType.MANUAL_VOUCHER_REDEMPTION) !== -1 &&
        dataSteps.subSteps
    ) {
        const voucherPathIndex = dataSteps.subSteps.findIndex((p) => p.i18nKey === 'manualVoucherRedemption')
        const instalationPathIndex = dataSteps.subSteps.findIndex((p) => p.i18nKey === 'installationDetails')
        if (voucherPathIndex === -1 && instalationPathIndex !== -1) {
            dataSteps.subSteps.splice(instalationPathIndex + 1, 0, {
                i18nKey: 'manualVoucherRedemption',
                status: ProgressListItemState.UNFINISHED,
            })
        }
    }

    if (currentViewType === ViewType.AVAILABILITY_CHECK) {
        availSteps.status = ProgressListItemState.ACTIVE
    }
    if (generalState.selectedProductCategories.length > 0) {
        const subSteps: ProgressListSubItemtype[] = []
        generalState.selectedProductCategories.forEach((selectedProductCategory) => {
            const selectedProductCategoryFromData = getSelectedProductCategoryFromData(
                generalState.availableProductCategories,
                selectedProductCategory.id,
            )
            if (selectedProductCategoryFromData) {
                subSteps.push({
                    i18nKey: selectedProductCategoryFromData.title,
                    status: ProgressListItemState.UNFINISHED,
                })
            }
        })
        confSteps.subSteps = subSteps
    }
    if (
        currentViewType === ViewType.PRODUCT_CATEGORIES_SELECTION ||
        currentViewType === ViewType.PRODUCT_SELECTION ||
        currentViewType === ViewType.PRODUCT_TYPE_SELECTION
    ) {
        availSteps.status = ProgressListItemState.FINISHED
        confSteps.status = ProgressListItemState.ACTIVE
        if (confSteps.subSteps) {
            confSteps.subSteps.forEach((_, index) => {
                const id = currentPath.split('/')[2]
                if (id !== '' && confSteps.subSteps) {
                    const selectedId = generalState.selectedProductCategories[index].id
                    if (id === selectedId) {
                        confSteps.subSteps[index].status = ProgressListItemState.ACTIVE
                        if (index > 0) {
                            for (let i = index - 1; i >= 0; i--) {
                                confSteps.subSteps[i].status = ProgressListItemState.FINISHED
                            }
                        }
                    }
                }
            })
        }
    }
    if (
        currentViewType === ViewType.OPTIONS_CONFIGURATION ||
        currentViewType === ViewType.PRODUCT_TYPE_SELECTION ||
        currentViewType === ViewType.VZF_VIEW ||
        currentViewType === ViewType.VZF_LANDINGPAGE
    ) {
        availSteps.status = ProgressListItemState.FINISHED
        confSteps.status = ProgressListItemState.ACTIVE
        if (confSteps.subSteps) {
            confSteps.subSteps.forEach((_, index) => {
                const id = currentPath.split('/')[2]
                if (id !== '' && confSteps.subSteps) {
                    const selectedProduct = generalState.selectedProductCategories[index].selectedProduct
                    if (selectedProduct) {
                        const selectedId = selectedProduct.id
                        if (id === selectedId) {
                            confSteps.subSteps[index].status = ProgressListItemState.ACTIVE
                            if (index > 0) {
                                for (let i = index - 1; i >= 0; i--) {
                                    confSteps.subSteps[i].status = ProgressListItemState.FINISHED
                                }
                            }
                        }
                    }
                }
            })
        }
    }

    if (
        currentViewType === ViewType.CONTACT_DATA ||
        currentViewType === ViewType.LANDLINE_CONTRACT_OPTIONS ||
        currentViewType === ViewType.PORTABILITY_DETAILS ||
        currentViewType === ViewType.BANK_DETAILS ||
        currentViewType === ViewType.DESIRED_DATE ||
        currentViewType === ViewType.MANUAL_VOUCHER_REDEMPTION ||
        currentViewType === ViewType.INSTALLATION_DETAILS
    ) {
        availSteps.status = ProgressListItemState.FINISHED
        confSteps.status = ProgressListItemState.FINISHED
        vzfSteps.status = ProgressListItemState.FINISHED
        if (confSteps.subSteps) {
            confSteps.subSteps.forEach((subStep) => (subStep.status = ProgressListItemState.FINISHED))
        }
        dataSteps.status = ProgressListItemState.ACTIVE

        if (dataSteps.subSteps) {
            dataSteps.subSteps.forEach((subStep, index) => {
                if (dataSteps.subSteps) {
                    if (currentViewType === subStep.i18nKey) {
                        dataSteps.subSteps[index].status = ProgressListItemState.ACTIVE
                        if (index > 0) {
                            for (let i = index - 1; i >= 0; i--) {
                                dataSteps.subSteps[i].status = ProgressListItemState.FINISHED
                            }
                        }
                    }
                }
            })
        }
    }

    if (currentViewType === ViewType.ORDER_CONFIRMATION || currentViewType === ViewType.ORDER_OVERVIEW) {
        vzfSteps.status = ProgressListItemState.FINISHED
        availSteps.status = ProgressListItemState.FINISHED
        confSteps.status = ProgressListItemState.FINISHED
        if (confSteps.subSteps) {
            confSteps.subSteps.forEach((subStep) => (subStep.status = ProgressListItemState.FINISHED))
        }
        dataSteps.status = ProgressListItemState.FINISHED
        if (dataSteps.subSteps) {
            dataSteps.subSteps.forEach((subStep) => (subStep.status = ProgressListItemState.FINISHED))
        }
        overviewSteps.status = ProgressListItemState.ACTIVE
    }
    if (currentViewType === ViewType.VZF_VIEW || currentViewType === ViewType.VZF_LANDINGPAGE) {
        confSteps.status = ProgressListItemState.FINISHED
        if (confSteps.subSteps) {
            confSteps.subSteps.forEach((val) => {
                val.status = ProgressListItemState.FINISHED
            })
        }
        vzfSteps.status = ProgressListItemState.ACTIVE
    }

    const progressList: ProgressListProps = {
        steps: [],
    }
    if (b2b) {
        progressList.steps = [availSteps, confSteps, dataSteps, overviewSteps]
    } else {
        progressList.steps = [availSteps, confSteps, vzfSteps, dataSteps, overviewSteps]
    }
    return progressList
}
