import { Box, List, ListItem, ListItemIcon, ListItemText, ListSubheader } from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Error, ViewType } from 'store/GeneralState/GeneralState.reducer'

export interface ErrorBoxProps {
    categoryToShow?: string | ViewType
    errors: Error[]
}

const ErrorBox = (props: ErrorBoxProps): JSX.Element => {
    const { categoryToShow, errors } = props
    const { t } = useTranslation()

    if (
        (categoryToShow && errors.find((error) => error.category === categoryToShow) === undefined) ||
        (!categoryToShow && errors.find((error) => error.category === '') === undefined)
    ) {
        return <Box />
    }

    return (
        <List className={'ErrorBox'}>
            {errors.map((error, index) => (
                <Box mt={index === 0 ? 0 : 2} key={index}>
                    {categoryToShow && error.category === categoryToShow && (
                        <ListSubheader
                            className={'ErrorBoxSubheader'}
                            component={'div'}
                            disableGutters={true}
                            disableSticky={true}
                        >
                            {t('error.' + error.category + '.header')}
                        </ListSubheader>
                    )}
                    {((categoryToShow && error.category === categoryToShow) ||
                        (!categoryToShow && error.category === '')) &&
                        error.errors.map((errorMessage) => (
                            <ListItem key={errorMessage + index}>
                                <ListItemIcon>
                                    <FiberManualRecordIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    {t(
                                        'error.' +
                                            (error.category !== '' ? error.category + '.' : 'generalError.') +
                                            errorMessage,
                                    )}
                                </ListItemText>
                            </ListItem>
                        ))}
                </Box>
            ))}
        </List>
    )
}

export default ErrorBox
