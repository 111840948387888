import { OptionItemContent } from 'components/OptionItem/OptionItem'
import { Dispatch, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { OptionType, Page, ViewType } from 'store/GeneralState/GeneralState.reducer'
import { AppState } from 'store/store'
import { optionCategoryTransform } from 'utils/testable/optionCategoryTransform'
import useURLParams from 'utils/URLParamsContex'
import { OptionsCategoryProps } from '../../components/OptionsCategory/OptionsCategory'
import { Option, OptionCategory, ProductCategory, ProductType } from '../../graphql/types'
import GeneralStateActions, { GeneralStateAction } from '../../store/GeneralState/GeneralState.actions'
import { checkForSelectedOptionCategory } from '../../utils/testable/checkForSelectedOptionCategory'
import { getProductFromData } from '../../utils/testable/getProductFromData'

interface OptionsConfigurationReducerReturn {
    currentView: ViewType
    optionsCategoriesToShow: OptionsCategoryProps[]
    productTypeFromData: ProductType | undefined
    availableProductCategories: ProductCategory[]
    pagesList: Page[]
    currentPage: number
    disabledSubmit: boolean
}

interface OptionsConfigurationParam {
    productID: string
    productType: string
    viewType: string
}

export const useOptionsConfigurationReducer: () => OptionsConfigurationReducerReturn = () => {
    const dispatch = useDispatch<Dispatch<GeneralStateActions>>()

    const [productTypeFromData, setProductTypeFromData] = useState<ProductType>()
    const [optionsCategoriesToShow, setOptionsCategoriesToShow] = useState<Array<OptionsCategoryProps>>([])

    const history = useHistory()
    const { B2B } = useURLParams()
    const { productID, productType, viewType } = useParams<OptionsConfigurationParam>()

    const {
        availableProductCategories,
        currentView,
        selectedProductCategories,
        currentPage,
        pagesList,
        optionsMultipleSelect,
    } = useSelector((appState: AppState) => {
        return {
            availableProductCategories: appState.generalState.availableProductCategories,
            currentView: appState.generalState.currentView,
            selectedProductCategories: appState.generalState.selectedProductCategories,
            pagesList: appState.generalState.pagesList,
            currentPage: appState.generalState.currentPage,
            optionsMultipleSelect: appState.generalState.optionsMultipleSelect,
        }
    })

    const setSelectedOption = useCallback(
        (payload: {
            productCategoryId: string
            productId: string
            productTypeId: string
            optionCategoryId: string
            optionId: string
            typeOfOptionCategory: OptionType
            viewType: string
            B2B: boolean
        }) => {
            dispatch({ type: GeneralStateAction.SELECT_PRODUCT_OPTION, payload })
        },
        [dispatch],
    )

    const setOptionsMultipleSelect = useCallback(
        (payload: { id: string; counter: number }) => {
            dispatch({ type: GeneralStateAction.SET_PRODUCT_OPTION_MULTIPLE, payload })
        },
        [dispatch],
    )

    const decreaseOptionSelect = (
        id: string,
        min: number,
        optionID: string,
        optionCategoryID: string,
        productTypeToShow: ProductType,
        selectedProductCategoryID: string,
        productID: string,
        productTypeToShowID: string,
        categoryType: OptionType,
        viewType: string,
    ) => {
        let counter = optionsMultipleSelect.get(id)
        if (counter !== undefined && counter > min) {
            setOptionsMultipleSelect({ id, counter: --counter })
        }
        setNotSelectedOption(
            optionID,
            optionCategoryID,
            productTypeToShow,
            selectedProductCategoryID,
            productID,
            productTypeToShowID,
            categoryType,
            viewType,
        )
    }

    const increaseOptionSelect = (
        id: string,
        min: number,
        max: number,
        optionID: string,
        optionCategoryID: string,
        productTypeToShow: ProductType,
        selectedProductCategoryID: string,
        productID: string,
        productTypeToShowID: string,
        categoryType: OptionType,
        viewType: string,
    ) => {
        let counter = optionsMultipleSelect.get(id)
        if (counter === undefined) {
            setOptionsMultipleSelect({ id, counter: ++min })
        } else if (counter < max) {
            setOptionsMultipleSelect({ id, counter: ++counter })
        }
        setNotSelectedOption(
            optionID,
            optionCategoryID,
            productTypeToShow,
            selectedProductCategoryID,
            productID,
            productTypeToShowID,
            categoryType,
            viewType,
        )
    }

    const setNotSelectedOption = (
        optionID: string,
        optionCategoryID: string,
        productTypeToShow: ProductType,
        selectedProductCategoryID: string,
        productID: string,
        productTypeToShowID: string,
        categoryType: OptionType,
        viewType: string,
    ) => {
        const selectedOptions = getSelectedOptionsForThisCategory(optionCategoryID, productTypeToShow)
        if (selectedOptions.indexOf(optionID) == -1) {
            setSelectedOption({
                optionCategoryId: optionCategoryID,
                optionId: optionID,
                productCategoryId: selectedProductCategoryID,
                productId: productID,
                productTypeId: productTypeToShowID,
                typeOfOptionCategory: categoryType,
                viewType,
                B2B,
            })
        }
    }

    const getOptionsMultipleSelect = (): Map<string, number> => {
        return optionsMultipleSelect
    }

    const getSelectedOptionsForThisCategory = (
        categoryID: string,
        productTypeFromData: ProductType | undefined,
    ): string[] => {
        const idsToReturn: string[] = []

        const selectedProductCategory = selectedProductCategories.find(
            (value) => value.selectedProduct && value.selectedProduct.id === productID,
        )

        selectedProductCategories.forEach((selectedProductCategory) => {
            if (selectedProductCategory.selectedProduct) {
                selectedProductCategory.selectedProduct.productTypes.forEach((productType) => {
                    if (productType.optionCategories.length > 0) {
                        productType.optionCategories.forEach((category) => {
                            if (category.id === categoryID) {
                                idsToReturn.push(...category.selectedOptions.map((option) => option))
                            }
                        })
                    }
                })
            }
        })

        if (idsToReturn.length === 0 && productTypeFromData) {
            productTypeFromData.category.forEach((optionCategory) => {
                if (optionCategory.id === categoryID) {
                    optionCategory.options.forEach((options) => {
                        if (options.isDefault) {
                            idsToReturn.push(options.id)
                            setSelectedOption({
                                optionCategoryId: optionCategory.id,
                                optionId: options.id,
                                productCategoryId: selectedProductCategory ? selectedProductCategory.id : '',
                                productId: productID,
                                productTypeId: productTypeFromData.id,
                                typeOfOptionCategory:
                                    optionCategory.max === 1 && optionCategory.min === 1
                                        ? OptionType.RADIO
                                        : OptionType.CHECKBOX,
                                viewType,
                                B2B,
                            })
                        }
                    })
                }
            })
        }

        return idsToReturn
    }

    const getDisabledOptionsForThisCategory = (
        optionsCategory: OptionCategory | OptionsCategoryProps,
        selectedOptions: string[],
    ): string[] => {
        const idsToReturn: string[] = []
        optionsCategory.options.forEach((option: Option | OptionItemContent) => {
            if (option.preconditionArticle && option.preconditionArticle.length > 0) {
                if (!option.preconditionArticle.some((pc) => selectedOptions.includes(pc))) {
                    idsToReturn.push(option.id)
                }
            }
        })
        return idsToReturn
    }

    useLayoutEffect(
        () => {
            if (productID !== '') {
                const selectedProductCategory = selectedProductCategories.find(
                    (value) => value.selectedProduct && value.selectedProduct.id === productID,
                )
                if (selectedProductCategory) {
                    const productFromData = getProductFromData(availableProductCategories, productID)
                    if (productFromData) {
                        //Combi
                        if (productFromData.productTypes.length > 1) {
                            if (productType !== undefined && productType !== '') {
                                const productTypeToShow = productFromData.productTypes.find(
                                    (pt) => pt.identifier === productType,
                                )
                                if (productTypeToShow) {
                                    setProductTypeFromData(productTypeToShow)
                                    const transformedOptionCategories: OptionsCategoryProps[] = []
                                    productTypeToShow.category.forEach((optionCategory) => {
                                        if (optionCategory.type === viewType) {
                                            const transformedOptionCategory = optionCategoryTransform(
                                                optionCategory,
                                                B2B,
                                            )
                                            transformedOptionCategory.onSelect = (id: string): void =>
                                                setSelectedOption({
                                                    optionCategoryId: optionCategory.id,
                                                    optionId: id,
                                                    productCategoryId: selectedProductCategory.id,
                                                    productId: productID,
                                                    productTypeId: productTypeToShow.id,
                                                    typeOfOptionCategory: transformedOptionCategory.categoryType,
                                                    viewType,
                                                    B2B,
                                                })

                                            transformedOptionCategory.increaseSelection = (
                                                id: string,
                                                min: number,
                                                max: number,
                                            ) => {
                                                increaseOptionSelect(
                                                    id,
                                                    min,
                                                    max,
                                                    id,
                                                    optionCategory.id,
                                                    productTypeToShow,
                                                    selectedProductCategory.id,
                                                    productID,
                                                    productTypeToShow.id,
                                                    transformedOptionCategory.categoryType,
                                                    viewType,
                                                )
                                            }
                                            transformedOptionCategory.decreaseSelection = (id: string, min: number) => {
                                                decreaseOptionSelect(
                                                    id,
                                                    min,
                                                    id,
                                                    optionCategory.id,
                                                    productTypeToShow,
                                                    selectedProductCategory.id,
                                                    productID,
                                                    productTypeToShow.id,
                                                    transformedOptionCategory.categoryType,
                                                    viewType,
                                                )
                                            }
                                            transformedOptionCategory.optionsMultipleSelect = getOptionsMultipleSelect()
                                            transformedOptionCategory.selected = getSelectedOptionsForThisCategory(
                                                optionCategory.id,
                                                productTypeToShow,
                                            )
                                            transformedOptionCategory.disabled = getDisabledOptionsForThisCategory(
                                                optionCategory,
                                                getSelectedOptionsForThisCategory(optionCategory.id, productTypeToShow),
                                            )

                                            transformedOptionCategories.push(transformedOptionCategory)
                                        }
                                    })
                                    setOptionsCategoriesToShow(transformedOptionCategories)
                                }
                            }
                        } else {
                            const productTypeToShow = productFromData.productTypes[0]
                            if (productTypeToShow) {
                                setProductTypeFromData(productTypeToShow)
                                const transformedOptionCategories: OptionsCategoryProps[] = []
                                productTypeToShow.category.forEach((optionCategory) => {
                                    if (optionCategory.type === viewType) {
                                        const transformedOptionCategory = optionCategoryTransform(optionCategory, B2B)
                                        transformedOptionCategory.onSelect = (id: string): void => {
                                            setSelectedOption({
                                                optionCategoryId: optionCategory.id,
                                                optionId: id,
                                                productCategoryId: selectedProductCategory.id,
                                                productId: productID,
                                                productTypeId: productTypeToShow.id,
                                                typeOfOptionCategory: transformedOptionCategory.categoryType,
                                                viewType,
                                                B2B,
                                            })
                                        }

                                        transformedOptionCategory.increaseSelection = (
                                            id: string,
                                            min: number,
                                            max: number,
                                        ) => {
                                            increaseOptionSelect(
                                                id,
                                                min,
                                                max,
                                                id,
                                                optionCategory.id,
                                                productTypeToShow,
                                                selectedProductCategory.id,
                                                productID,
                                                productTypeToShow.id,
                                                transformedOptionCategory.categoryType,
                                                viewType,
                                            )
                                        }
                                        transformedOptionCategory.decreaseSelection = (id: string, min: number) => {
                                            decreaseOptionSelect(
                                                id,
                                                min,
                                                id,
                                                optionCategory.id,
                                                productTypeToShow,
                                                selectedProductCategory.id,
                                                productID,
                                                productTypeToShow.id,
                                                transformedOptionCategory.categoryType,
                                                viewType,
                                            )
                                        }
                                        transformedOptionCategory.optionsMultipleSelect = getOptionsMultipleSelect()
                                        transformedOptionCategory.selected = getSelectedOptionsForThisCategory(
                                            optionCategory.id,
                                            productTypeToShow,
                                        )
                                        transformedOptionCategory.disabled = getDisabledOptionsForThisCategory(
                                            optionCategory,
                                            getSelectedOptionsForThisCategory(optionCategory.id, productTypeToShow),
                                        )
                                        transformedOptionCategories.push(transformedOptionCategory)
                                    }
                                })
                                setOptionsCategoriesToShow(transformedOptionCategories)
                            }
                        }
                    }
                }
            }
        },
        // eslint-disable-next-line
        [history.location.pathname],
    )

    useEffect(
        () => {
            if (optionsCategoriesToShow.length > 0) {
                const modifiedOptionsCategoriesToShow = [...optionsCategoriesToShow]
                modifiedOptionsCategoriesToShow.forEach((optionsCategoryProps) => {
                    optionsCategoryProps.selected = getSelectedOptionsForThisCategory(
                        optionsCategoryProps.categoryId,
                        productTypeFromData,
                    )
                    optionsCategoryProps.disabled = getDisabledOptionsForThisCategory(
                        optionsCategoryProps,
                        getSelectedOptionsForThisCategory(optionsCategoryProps.categoryId, productTypeFromData),
                    )
                })
                setOptionsCategoriesToShow(modifiedOptionsCategoriesToShow)
            }
        },
        // eslint-disable-next-line
        [selectedProductCategories],
    )

    const disabledSubmit = useMemo(() => {
        let disabled = false
        if (productID !== '') {
            const selectedProductCategory = selectedProductCategories.find(
                (value) => value.selectedProduct && value.selectedProduct.id === productID,
            )
            if (selectedProductCategory) {
                const productFromData = getProductFromData(availableProductCategories, productID)
                if (productFromData) {
                    if (productFromData.productTypes.length > 1) {
                        // Is a combi product
                        if (productType !== undefined && productType !== '') {
                            const productTypeToCheck = productFromData.productTypes.find(
                                (pt) => pt.identifier === productType,
                            )
                            if (productTypeToCheck) {
                                productTypeToCheck.category.forEach((category) => {
                                    if (category.type === viewType) {
                                        if (disabled === false) {
                                            const selectedOptionCategory = checkForSelectedOptionCategory(
                                                selectedProductCategory,
                                                productTypeToCheck.id,
                                                category.id,
                                            )
                                            if (selectedOptionCategory) {
                                                if (
                                                    selectedOptionCategory.selectedOptions.length >= category.min &&
                                                    selectedOptionCategory.selectedOptions.length <= category.max
                                                ) {
                                                    disabled = false
                                                } else {
                                                    disabled = true
                                                }
                                            } else {
                                                if (category.min === 0) {
                                                    disabled = false
                                                } else {
                                                    disabled = true
                                                }
                                            }
                                        }
                                    }
                                })
                            }
                        }
                    } else {
                        const productTypeToCheck = productFromData.productTypes[0]
                        productTypeToCheck.category.forEach((category) => {
                            if (category.type === viewType) {
                                if (disabled === false) {
                                    const selectedOptionCategory = checkForSelectedOptionCategory(
                                        selectedProductCategory,
                                        productTypeToCheck.id,
                                        category.id,
                                    )
                                    if (selectedOptionCategory) {
                                        if (
                                            selectedOptionCategory.selectedOptions.length >= category.min &&
                                            selectedOptionCategory.selectedOptions.length <= category.max
                                        ) {
                                            disabled = false
                                        } else {
                                            disabled = true
                                        }
                                    } else {
                                        if (category.min === 0) {
                                            disabled = false
                                        } else {
                                            disabled = true
                                        }
                                    }
                                }
                            }
                        })
                    }
                }
            }
        }

        return disabled
    }, [history.location.pathname, availableProductCategories, selectedProductCategories])

    return {
        currentView,
        optionsCategoriesToShow,
        productTypeFromData,
        availableProductCategories,
        currentPage,
        pagesList,
        disabledSubmit,
    }
}
