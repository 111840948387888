import * as React from 'react'

export interface CheckIconProps {
    color: string
    width: string
    height: string
}

const CheckIcon = (props: CheckIconProps): JSX.Element => {
    return (
        <svg
            width={props.width}
            height={props.height}
            viewBox={'0 0 20 14'}
            fill={'none'}
            xmlns={'http://www.w3.org/2000/svg'}
        >
            <path
                d={'M16.844 0L7.394 9.45L2.187 4.243L0 6.429L7.394 13.823L19.031 2.186L16.844 0Z'}
                fill={props.color}
            />
        </svg>
    )
}

export default CheckIcon
