import { sumBy } from 'lodash'
import { OptionType } from 'store/GeneralState/GeneralState.reducer'
import { OptionItemContent } from '../../components/OptionItem/OptionItem'
import { OptionsCategoryProps } from '../../components/OptionsCategory/OptionsCategory'
import { Discount, Option, OptionCategory } from '../../graphql/types'

export const optionsTransform = (options: Option[], b2b: boolean): OptionItemContent[] => {
    const result: OptionItemContent[] = []
    options
        .sort((a, b) => a.sortIndex - b.sortIndex)
        .forEach((option) => {
            const optionPrice = b2b ? option.priceNet : option.gross
            let description: string[] | undefined
            let oldPrice: number | undefined
            let newPrice: number = optionPrice
            if (option.discounts.length > 0) {
                description = option.discounts.map((discount) => discount.description)
                oldPrice = optionPrice
                newPrice =
                    oldPrice -
                    sumBy(option.discounts, function (d: Discount) {
                        return b2b ? d.priceNet : d.gross
                    })
            }

            result.push({
                id: option.id,
                title: option.title,
                price: {
                    newPrice,
                    oldPrice,
                    description,
                    billMode: option.billMode,
                    info: option.information ? { title: option.information } : undefined,
                    b2b: b2b,
                },
                selectedImage: option.badgeIdentifier ? option.badgeIdentifier + 'FreeActive.png' : undefined,
                unselectedImage: option.badgeIdentifier ? option.badgeIdentifier + 'FreeInactive.png' : undefined,
                subtitle: option.subtitle !== null ? option.subtitle : undefined,
                multiSelectedMin: option.min ?? 0,
                multiSelectedMax: option.max ?? 0,
                multiSelectedEnabled: option.max !== null && option.min !== null,
                preconditionArticle: option.preconditionArticle,
            })
        })
    return result
}

export const optionCategoryTransform = (optionCategory: OptionCategory, b2b: boolean): OptionsCategoryProps => {
    const result: OptionsCategoryProps = {
        categoryId: optionCategory.id,
        categoryType: optionCategory.max === 1 && optionCategory.min === 1 ? OptionType.RADIO : OptionType.CHECKBOX,
        header: optionCategory.title,
        subheader: optionCategory.information !== null ? optionCategory.information : undefined,
        // eslint-disable-next-line
        onSelect: () => {},
        // eslint-disable-next-line
        increaseSelection: () => {},
        // eslint-disable-next-line
        decreaseSelection: () => {},
        optionsMultipleSelect: new Map<string, number>(),
        options: optionsTransform(optionCategory.options, b2b),
        selected: [],
        disabled: [],
    }
    return result
}
