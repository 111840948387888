import { Box, Button, CircularProgress, Collapse, Fade, Grid, Hidden, Typography } from '@material-ui/core'
import clsx from 'clsx'
import Basket from 'components/Basket'
import ErrorBox from 'components/ErrorBox'
import MobileProgressList from 'components/MobileProgressList/MobileProgressList'
import ProgressList from 'components/ProgressList'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { getTestIDOnViewType } from 'utils/TestIDs'
import { ViewType } from '../../store/GeneralState/GeneralState.reducer'
import { basketCalculation } from '../../utils/testable/basketCalculation'
import { updateProgressList } from '../../utils/testable/updateProgressList'
import InfoTooltip from '../InfoTooltip'
import { useViewWrapperReducer } from './useViewWrapperReducer'

export interface ViewWrapperProps {
    children: JSX.Element
    disabledSubmit: boolean
    header: string
    subHeader: string
    submitButtonText?: string
    submitButtonCustomCssSelector?: string
    submitRef?: ((instance: HTMLButtonElement | null) => void) | React.RefObject<HTMLButtonElement> | null | undefined
    viewType: ViewType
    disableBasket?: boolean
    disableButtons?: boolean
    tooltipText?: string
    errorCategory?: string | ViewType
    disableBackButton?: boolean
    onSubmitAlt?: () => void
}

const ViewWrapper = (props: ViewWrapperProps): JSX.Element => {
    const { t } = useTranslation()
    const {
        children,
        disabledSubmit,
        header,
        subHeader,
        submitButtonText,
        submitButtonCustomCssSelector,
        submitRef,
        tooltipText,
        viewType,
        disableBasket,
        disableButtons,
        errorCategory,
        disableBackButton,
        onSubmitAlt,
    } = props
    const { availabilityCheck, generalState, bankDetails, loadState, minHeight, onSubmit, onBack, errors, B2B, vzfID } =
        useViewWrapperReducer()
    let newErrorCategory = errorCategory
    if (newErrorCategory === undefined && viewType === ViewType.AVAILABILITY_CHECK && errors.length > 0) {
        newErrorCategory = ViewType.PRODUCT_CATEGORIES_SELECTION
    }

    return (
        <Box minHeight={minHeight} paddingLeft={3} pr={{ xs: 3, sm: 6 }} paddingTop={2} paddingBottom={2}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={2} />
                <Grid item xs={12} md={10}>
                    <Hidden mdUp={true}>
                        <MobileProgressList {...updateProgressList(generalState, B2B)} />
                    </Hidden>
                    <Typography
                        {...getTestIDOnViewType(viewType, 'HEADER_TYPOGRAPHY')}
                        variant={'h1'}
                        className={clsx({ TextError: !loadState.loading && loadState.errors })}
                        dangerouslySetInnerHTML={{
                            __html: !loadState.loading && loadState.errors ? t(loadState.errors) : t(header),
                        }}
                    />
                    <Typography {...getTestIDOnViewType(viewType, 'SUBHEADER_TYPOGRAPHY')} variant={'h4'}>
                        {t(subHeader)}{' '}
                        {tooltipText && (
                            <Box display={'inline'}>
                                <Box display={'inline'} justifyContent={{ xs: 'flex-end', sm: 'center' }}>
                                    <InfoTooltip
                                        title={tooltipText}
                                        iconStyle={{ verticalAlign: 'middle' }}
                                        placement={'bottom-end'}
                                    />
                                </Box>
                            </Box>
                        )}
                    </Typography>
                </Grid>
                <Hidden smDown={true}>
                    <Grid item xs={12} md={2}>
                        <ProgressList {...updateProgressList(generalState, B2B)} />
                    </Grid>
                </Hidden>
                <Grid item xs={12} md={10}>
                    <Grid container item xs={12} lg={10} xl={9} direction={'column'} justifyContent={'space-between'}>
                        <Fade
                            in={loadState.loading}
                            timeout={250}
                            unmountOnExit={true}
                            exit={false}
                            disableStrictModeCompat={true}
                        >
                            <Box
                                height={1}
                                width={1}
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                marginTop={5}
                                marginBottom={{ xs: 5, sm: 0 }}
                            >
                                <CircularProgress size={80} />
                            </Box>
                        </Fade>
                        <Fade
                            in={!loadState.loading}
                            timeout={250}
                            unmountOnExit={true}
                            exit={false}
                            disableStrictModeCompat={true}
                        >
                            <>
                                {children}
                                <Collapse
                                    in={errors.length > 0}
                                    unmountOnExit={true}
                                    timeout={250}
                                    disableStrictModeCompat={true}
                                >
                                    <Grid container item xs={12}>
                                        <ErrorBox errors={errors} categoryToShow={newErrorCategory} />
                                    </Grid>
                                </Collapse>
                                {!disableButtons && (
                                    <Box width={1} marginTop={2} display={'flex'} justifyContent={'space-between'}>
                                        <Grid container justifyContent={'space-between'}>
                                            <Grid item xs={12} sm>
                                                {disableBackButton !== true && (
                                                    <Button
                                                        disableFocusRipple={true}
                                                        {...getTestIDOnViewType(viewType, 'BACK_BUTTON')}
                                                        disabled={generalState.currentPage === 0}
                                                        className={clsx(['FormButton', 'BackButton'])}
                                                        onClick={onBack}
                                                    >
                                                        {t('goBack')}
                                                    </Button>
                                                )}
                                            </Grid>
                                            <Grid container item xs={12} sm justifyContent={'flex-end'}>
                                                <Button
                                                    ref={submitRef}
                                                    disableFocusRipple={true}
                                                    {...getTestIDOnViewType(viewType, 'SUBMIT_BUTTON')}
                                                    disabled={disabledSubmit}
                                                    className={clsx([
                                                        'FormButton',
                                                        'NextButton',
                                                        submitButtonCustomCssSelector,
                                                    ])}
                                                    onClick={onSubmitAlt ? onSubmitAlt : onSubmit}
                                                >
                                                    {submitButtonText ? submitButtonText : t(viewType + 'ButtonText')}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )}
                            </>
                        </Fade>
                    </Grid>
                    {!disableBasket && (
                        <Basket
                            vzfID={vzfID}
                            {...basketCalculation(availabilityCheck, bankDetails, generalState, B2B, false)}
                        />
                    )}
                </Grid>
            </Grid>
        </Box>
    )
}

export default ViewWrapper
