import { gql } from '@apollo/client'

export default gql`
    mutation completeOrderProgress(
        $order: OrderDataInput!
        $pdf: PDFDataInput!
        $vzfID: String!
        $vzfSummaryData: VZFPDFData!
        $B2B: Boolean!
    ) {
        completeOrderProgress(order: $order, pdf: $pdf, vzfID: $vzfID, vzfSummaryData: $vzfSummaryData, B2B: $B2B) {
            success
            orderNumber
            creditCheck
        }
    }
`
