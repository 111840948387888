import { Box, ButtonBase, Grid, Typography } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { OptionType } from 'store/GeneralState/GeneralState.reducer'
import colors from 'utils/colors'
import TestIDs from 'utils/TestIDs'

export interface ConfigurationItemOption {
    columnValue: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined
    label: string
    mobileColumnValue: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined
    value: string
}

export interface ConfigurationItemProps {
    onSelect: (value: string) => void
    option: ConfigurationItemOption
    paddingRight?: string
    selected: boolean
    type: OptionType
}

const ConfigurationItem = (props: ConfigurationItemProps): JSX.Element => {
    const { t } = useTranslation()
    const { selected, option, onSelect, type } = props

    return (
        <Grid
            item
            xs={option.mobileColumnValue}
            md={option.columnValue}
            key={option.value}
            className={selected ? 'Selected' : ''}
        >
            <ButtonBase
                {...TestIDs.GET_CONFIGURATION('ITEM', 'BUTTON', option.value)}
                onClick={(): void => onSelect(option.value)}
                className={'ConfigurationItem'}
                style={{
                    backgroundColor: selected ? colors.color2 : colors.lightGray,
                    border: `2px solid ${selected ? colors.color2 : colors.midGray}`,
                }}
            >
                <Grid container style={{ flexWrap: 'nowrap' }}>
                    <Grid
                        item
                        className={'ConfigurationItemTitleContainer'}
                        style={{
                            backgroundColor: selected ? 'transparent' : colors.lightGray,
                        }}
                    >
                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            width={76}
                            height={1}
                            minHeight={76}
                        >
                            <Box
                                width={32}
                                height={32}
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                className={
                                    type === OptionType.CHECKBOX
                                        ? 'ConfigurationItemCheckbox'
                                        : 'ConfigurationItemRadio'
                                }
                                bgcolor={colors.white}
                            >
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        (type === OptionType.CHECKBOX
                                            ? '/customize/checkboxSelected.svg'
                                            : '/customize/radioSelected.svg')
                                    }
                                    alt={type === OptionType.CHECKBOX ? '✓' : '.'}
                                    style={{ opacity: selected ? 1 : 0, transition: '250ms all' }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        className={'OptionItemContentContainer'}
                        xs
                        style={{ backgroundColor: selected ? 'transparent' : colors.white }}
                    >
                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            height={1}
                            paddingLeft={1}
                            paddingRight={1}
                            minWidth={150}
                        >
                            <Grid xs container item className={'ConfigurationItem'}>
                                <Grid container item direction={'column'} spacing={1}>
                                    <Grid item>
                                        <Typography
                                            variant={'h2'}
                                            style={selected ? { color: colors.white } : undefined}
                                            className={'ConfigurationItemTitle'}
                                            dangerouslySetInnerHTML={{ __html: t(option.label) }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </ButtonBase>
        </Grid>
    )
}

export default ConfigurationItem
