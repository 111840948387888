import { Box, Typography } from '@material-ui/core'
import AvailabilityCheckInput from 'components/AvailabilityCheckInput'
import ViewWrapper from 'components/ViewWrapper'
import VZFInput from 'components/VZFInput'
import React, { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { AvailabilityCheckField } from 'store/AvailabilityCheck/AvailabilityCheck.actions'
import { ViewType } from 'store/GeneralState/GeneralState.reducer'
import { getTestIDOnViewType } from 'utils/TestIDs'
import useURLParams from 'utils/URLParamsContex'
import { getRoleAwareTranslationKey, useUserInfo } from 'utils/UserInfoContext'
import { useAvailabilityCheckReducer } from './useAvailabilityCheckReducer'

const AvailabilityCheck = (): JSX.Element => {
    const availabilitySubmitRef = useRef<HTMLButtonElement>(null)
    const {
        availableCities,
        availableHouseNumbers,
        availableStreets,
        cities,
        currentView,
        customizeJsData,
        focusedFieldId,
        houseNumbers,
        streets,
        zip,
        vzf,
        vzfNoResult,
        vzfLoading,
        selectedCity,
        setLoadState,
        selectedHouseNumber,
        selectedStreet,
        setSelectedCity,
        setSelectedHouseNumber,
        setSelectedStreet,
        setCities,
        setHouseNumbers,
        setStreets,
        setFocusedField,
        setZip,
        onChangeVZF,
        onClickCheckVZF,
        getAvailableCities,
        getAvailableHouseNumbers,
        getAvailableStreets,
    } = useAvailabilityCheckReducer()

    const { t } = useTranslation()

    const { vzf: paramsVZF, B2B } = useURLParams()

    useEffect(() => {
        if (paramsVZF && !B2B) onChangeVZF(paramsVZF)
    }, [paramsVZF])

    useEffect(() => {
        if (availableCities.data) {
            setCities(availableCities.data.availableCities)
        }
    }, [availableCities, setCities])

    useEffect(() => {
        if (cities.length === 1) {
            setSelectedCity(cities[0])
        } else if (cities.length > 1) {
            setFocusedField(AvailabilityCheckField.CITY)
        }
    }, [cities, setFocusedField, setSelectedCity])

    useEffect(() => {
        if (selectedCity !== '') {
            getAvailableStreets({
                variables: {
                    zipcode: zip,
                    city: selectedCity,
                },
            })
        }
    }, [selectedCity, getAvailableStreets, zip])

    useEffect(() => {
        if (availableStreets.data) {
            setStreets(availableStreets.data.availableStreets)
        }
    }, [availableStreets, setStreets, setSelectedStreet])

    useEffect(() => {
        if (streets.length === 1) {
            setSelectedStreet(streets[0])
        } else if (streets.length > 1) {
            setFocusedField(AvailabilityCheckField.STREET)
        }
    }, [streets, setFocusedField, setSelectedStreet])

    useEffect(() => {
        if (selectedStreet !== '') {
            getAvailableHouseNumbers({
                variables: {
                    zipcode: zip,
                    city: selectedCity,
                    street: selectedStreet,
                },
            })
        }
    }, [selectedStreet, getAvailableHouseNumbers, zip, selectedCity])

    useEffect(() => {
        if (availableHouseNumbers.data) {
            setHouseNumbers(availableHouseNumbers.data.availableHouseNumbers)
        }
    }, [availableHouseNumbers, setHouseNumbers, setSelectedHouseNumber])

    useEffect(() => {
        if (houseNumbers.length === 1) {
            setSelectedHouseNumber(houseNumbers[0])
            setFocusedField(AvailabilityCheckField.SUBMIT)
        } else if (houseNumbers.length > 1) {
            setFocusedField(AvailabilityCheckField.HOUSE_NUMBER)
        }
    }, [houseNumbers, setFocusedField, setSelectedHouseNumber])

    useEffect(() => {
        if (selectedHouseNumber !== '') {
            setFocusedField(AvailabilityCheckField.SUBMIT)
        }
    }, [selectedHouseNumber, setFocusedField])

    useEffect(() => {
        if (focusedFieldId === AvailabilityCheckField.SUBMIT && availabilitySubmitRef.current !== null) {
            availabilitySubmitRef.current.focus()
        }
    }, [focusedFieldId])

    const noProductLink = useMemo(() => {
        if (customizeJsData) {
            return customizeJsData.noProductAvailable.link
        } else {
            return ''
        }
    }, [customizeJsData])

    const [userInfo] = useUserInfo()

    return (
        <ViewWrapper
            disableBasket={true}
            submitButtonText={vzf.length > 0 ? t('vzf.vzfCheck') : undefined}
            disabledSubmit={vzf.length === 0 && selectedHouseNumber === ''}
            submitRef={availabilitySubmitRef}
            header={getRoleAwareTranslationKey(userInfo, 'availabilityCheckHeader')}
            subHeader={''}
            viewType={currentView}
            disableBackButton={true}
            onSubmitAlt={vzf.length > 0 ? onClickCheckVZF : undefined}
        >
            <>
                <Box pb={2}>
                    <Typography
                        {...getTestIDOnViewType(ViewType.AVAILABILITY_CHECK, 'SUBHEADER_TYPOGRAPHY')}
                        variant={'h4'}
                    >
                        {t(getRoleAwareTranslationKey(userInfo, 'availabilityCheckSubheader'))}{' '}
                    </Typography>
                </Box>
                <AvailabilityCheckInput
                    haveLink={customizeJsData?.noProductAvailable.haveLink}
                    noProductLink={noProductLink}
                    disabledCity={cities.length < 2}
                    disabledHouseNumber={houseNumbers.length < 2}
                    disabledStreet={streets.length < 2}
                    disabledZip={false}
                    cities={cities}
                    focusedFieldId={focusedFieldId}
                    houseNumbers={houseNumbers}
                    queriesAreFinished={
                        availableCities.loading === false &&
                        availableCities.called === true &&
                        (availableCities.data !== undefined || availableCities.error !== undefined)
                    }
                    selectedCity={selectedCity}
                    selectedHouseNumber={selectedHouseNumber}
                    selectedStreet={selectedStreet}
                    setFocusedField={setFocusedField}
                    setSelectedCity={(
                        payload: React.ChangeEvent<{
                            name?: string | undefined
                            value: unknown
                        }>,
                    ): void => {
                        setSelectedCity(payload.target.value as string)
                        setSelectedStreet('')
                        setStreets([])
                        setSelectedHouseNumber('')
                        setHouseNumbers([])
                        getAvailableStreets()
                    }}
                    setSelectedHouseNumber={(
                        payload: React.ChangeEvent<{
                            name?: string | undefined
                            value: unknown
                        }>,
                    ): void => {
                        setSelectedHouseNumber(payload.target.value as string)
                    }}
                    setSelectedStreet={(
                        payload: React.ChangeEvent<{
                            name?: string | undefined
                            value: unknown
                        }>,
                    ): void => {
                        setSelectedStreet(payload.target.value as string)
                        setSelectedHouseNumber('')
                        setHouseNumbers([])
                        getAvailableHouseNumbers()
                    }}
                    setZip={(
                        payload: React.ChangeEvent<{
                            name?: string | undefined
                            value: unknown
                        }>,
                    ): void => {
                        setSelectedCity('')
                        setCities([])
                        setSelectedStreet('')
                        setStreets([])
                        setSelectedHouseNumber('')
                        setHouseNumbers([])
                        setZip(payload.target.value as string)
                        if ((payload.target.value as string).length === 5) {
                            setLoadState({
                                loading: true,
                                errors: undefined,
                            })
                            getAvailableCities({ variables: { zipcode: payload.target.value as string } })
                        }
                    }}
                    streets={streets}
                    zip={zip}
                    autoFocusOnMount={true}
                />
                {!B2B && (
                    <VZFInput
                        vzf={vzf}
                        loading={vzfLoading}
                        onEnter={onClickCheckVZF}
                        noResult={vzfNoResult}
                        onChangeVZF={onChangeVZF}
                    />
                )}
            </>
        </ViewWrapper>
    )
}

export default AvailabilityCheck
