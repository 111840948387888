import { Box, Grid, TextField, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ViewType } from 'store/GeneralState/GeneralState.reducer'
import TestIDs from '../../utils/TestIDs'

export interface VZFInputProps {
    vzf: string
    onChangeVZF: (payload: string) => void
    loading?: boolean
    noResult?: boolean
    onEnter: () => void
}

const VZFInput = (props: VZFInputProps): JSX.Element => {
    const { t } = useTranslation()
    const { noResult, vzf, onChangeVZF, loading, onEnter } = props

    return (
        <Box py={2}>
            <Grid container {...TestIDs.GET_AVAILABILITY_CHECK('FORM')} justifyContent={'flex-end'}>
                <Grid container item xs={12} alignItems={'center'}>
                    <Grid item xs>
                        <Typography variant={'h4'}>{t('vzf.vzfInPossession')}</Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            {...TestIDs.GET_AVAILABILITY_CHECK('VZF_INPUT')}
                            error={noResult}
                            InputProps={{
                                className: noResult ? 'TextError' : '',
                            }}
                            label={t('vzf.vzfNumber')}
                            variant={'outlined'}
                            disabled={loading}
                            helperText={noResult ? t('error.' + ViewType.AVAILABILITY_CHECK + '.vzfNoResult') : ''}
                            value={vzf}
                            onChange={(e): void => onChangeVZF(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    onEnter()
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default VZFInput
