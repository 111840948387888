import { Box, Grid, Link, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import colors from 'utils/colors'

interface FooterLink {
    i18Key: string
    link: string
}

export interface FooterProps {
    footerLinks: FooterLink[]
}

const Footer = (props: FooterProps): JSX.Element => {
    const { t } = useTranslation()
    const { footerLinks } = props

    const hiddenVersion = require('./hiddenVersion.json')

    return (
        <Box bgcolor={colors.green} pt={2} pb={2} minHeight={{ xs: 80, sm: 40 }}>
            <Grid container>
                <Grid container item xs={12} md={2} alignItems={'center'} direction={'row'} justifyContent={'center'}>
                    <Box textAlign={{ xs: 'center', sm: 'left' }} marginBottom={{ xs: 2, md: 0 }}>
                        <img
                            src={process.env.PUBLIC_URL + '/customize/footerIcon.png'}
                            alt={'logo'}
                            style={{ maxHeight: 60, maxWidth: 140 }}
                        />
                    </Box>
                </Grid>
                <Box flex={1}>
                    <Grid container item xs={12} lg={9} xl={7} className={'FooterLinkWrapper'}>
                        {footerLinks.map((footerLink, index) => (
                            <Box
                                key={index}
                                marginRight={index !== footerLinks.length - 1 ? 2 : 0}
                                alignSelf={'center'}
                            >
                                {footerLink.link !== '' && (
                                    <Link
                                        target="_blank"
                                        className={'FooterLink'}
                                        href={footerLink.link}
                                        color={'secondary'}
                                    >
                                        {t(footerLink.i18Key)}
                                    </Link>
                                )}
                                {footerLink.link === '' && (
                                    <Typography className={'FooterLink'} color={'secondary'} variant={'h5'}>
                                        {t(footerLink.i18Key)}
                                    </Typography>
                                )}
                            </Box>
                        ))}

                        <Typography
                            className={'FooterLink'}
                            color={'primary'}
                            variant={'subtitle2'}
                            style={{ position: 'absolute', right: 0, bottom: 0 }}
                        >
                            Version:{hiddenVersion.version} Iteration:{hiddenVersion.iteration}
                        </Typography>
                    </Grid>
                </Box>
            </Grid>
        </Box>
    )
}

export default Footer
